/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const defaultDescription =
  "We back companies re-imagining, re-inventing and re-wiring the financial ecosystem"
const author = "@westonbaker"
const defaultImageURLFacebook = `https://tencoves.com/ten-coves-social-share-2.png`

function SEO({ siteTitle, description, lang, meta, title }) {
  const metaDescription = description || defaultDescription

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteTitle}`}
      meta={[
        {
          name: "google-site-verification",
          content: "_iAp2xv1X2knm253LIJm9ozT91B7Cu0Od0lR1Or4b-A",
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: defaultImageURLFacebook,
        },
        {
          property: `og:image`,
          content: defaultImageURLFacebook,
        },
        {
          name: `og:image:height`,
          content: `4168`,
        },
        {
          name: `og:image:width`,
          content: `4168`,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
