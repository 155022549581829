import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

import Box from "components/atoms/Box"

const StyledImg = styled("img")({
  height: "100%",
  width: "100%",
  objectFit: "contain",
})

/*
 * An SVG Icon on Strapi
 * - Note that icons are just normal images uploaded to strapi in SVG format
 * - This is a separate component because Gatsby Image can't be used with SVGs
 *   and also allows consistent styling of icons
 * - At some point we may want to embed this directly in the html so we can style it
 */
const Icon = ({ file, description, ...rest }) => {
  return (
    <Box {...rest}>
      {file && <StyledImg src={file.url} alt={description || ""} />}
    </Box>
  )
}

export default Icon

Icon.defaultProps = {
  width: 2,
  height: 2,
}

Icon.strapiProps = {
  url: PropTypes.object.isRequired,
  description: PropTypes.string,
}

Icon.propTypes = {
  ...Icon.strapiProps,
  ...Box.propTypes,
}

export const query = graphql`
  fragment Icon on ContentfulAsset {
    file {
      url
    }
    description
  }
`
