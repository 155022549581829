import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { ThemeProvider } from "styled-components"
import { theme } from "utils/styled-constants"

import HeaderDefault from "components/headers/HeaderDefault"
import FooterMinimal from "components/footers/FooterMinimal"
import "normalize.css"
import "styles/global.css"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({
  header,
  footer,
  footerTheme,
  headerInvert,
  headerBg,
  children,
}) => {
  let Header = () => {
    switch (header.__typename) {
      case "ContentfulDefaultHeader":
        return <HeaderDefault invert={headerInvert} bg={headerBg} {...header} />
      default:
        return null
    }
  }

  let Footer = props => {
    return <FooterMinimal {...header} {...footer} {...props} />
  }

  return (
    <>
      <Helmet>
        <noscript>
          {/* If there's no Javascript, show all react-reveal elements */}
          {`<style>.react-reveal {visibility: visible !important;}</style>`}
        </noscript>
      </Helmet>
      <ThemeProvider theme={theme}>
        <Header />
        <main style={{ position: "relative" }}>{children}</main>
        <Footer theme={footerTheme} />
      </ThemeProvider>
    </>
  )
}

export default Layout
