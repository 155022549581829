import React from "react"
import PropTypes from "prop-types"
import propTypes from "@styled-system/prop-types"
import { Link as GatsbyLink, StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import css from "@styled-system/css"
import { typography, space, compose, layout } from "styled-system"

import Link from "components/utils/Link"
import { LinkText } from "components/atoms/Typography"
import { colors } from "utils/styled-constants"

const StyledGatsbyLink = styled(GatsbyLink)`
  padding: 8px 0;
  ${props =>
    props.mobile
      ? null
      : css({
          color: colors.white,
          transition: "opacity 0.1s ease",
          "&:hover": {
            opacity: 0.5,
          },
        })}
        ${props =>
          props.footer
            ? null
            : props.invert
            ? css({
                color: colors.white,
                "&.active": {
                  color: colors.white,
                  borderBottom: `2px solid ${colors.teal}`,
                  opacity: 1,
                },
              })
            : css({
                color: colors.darkBlue,
                "&.active": {
                  color: colors.darkBlue,
                  borderBottom: `2px solid ${colors.teal}`,
                  opacity: 1,
                },
              })}
        }

  ${compose(space, layout, typography)}
`

/*
 * NavLink
 * - Used mainly for headers and footers
 * - For Gatsby Links, populates the text with the page title
 * - For external links, uses "hrefText" field
 */
const NavLink = ({
  internalLink,
  indexPageSlug,
  partiallyActive,
  linkText,
  variant,
  invert,
  ...rest
}) => {
  if (internalLink) {
    let pagePath
    if (internalLink.slug === indexPageSlug || internalLink.slug === "/") {
      pagePath = "/"
    } else {
      pagePath = "/" + internalLink.slug + "/"
    }
    const pageTitle = internalLink.title

    // only use partially active if path is not "/"
    if (partiallyActive && pagePath === "/") {
      partiallyActive = false
    }
    return (
      <StyledGatsbyLink
        invert={invert}
        to={pagePath}
        partiallyActive={partiallyActive}
        activeClassName="active"
        {...rest}
      >
        <LinkText variant={variant}>{linkText ? linkText : pageTitle}</LinkText>
      </StyledGatsbyLink>
    )
  }

  return (
    <Link {...rest}>
      <LinkText variant={variant}>{linkText}</LinkText>
    </Link>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query NavLinkIndexPageQuery {
        contentfulSite {
          indexPage {
            slug
          }
        }
      }
    `}
    render={data => (
      <NavLink indexPageSlug={data.contentfulSite.indexPage.slug} {...props} />
    )}
  />
)

NavLink.propTypes = {
  ...Link.strapiProps,
  indexPageSlug: PropTypes.string.isRequired,
  to: PropTypes.object, // must be a number here
  ...propTypes.space,
}
