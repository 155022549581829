import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled, { createGlobalStyle } from "styled-components"
import css from "@styled-system/css"
import { layout } from "styled-system"
import { animation } from "utils/styled-helpers"
import Img from "gatsby-image"

import CSSTransition from "components/utils/CSSTransition"
import ThemeSwitcher from "components/utils/ThemeSwitcher"

import useStoreScroll from "hooks/use-store-scroll"

import EmailAndPhone from "components/atoms/EmailAndPhone"
import StandardPadding from "components/atoms/StandardPadding"
import Flex from "components/atoms/Flex"
import Box from "components/atoms/Box"
import NavLink from "components/atoms/NavLink"
import Link from "components/utils/Link"
import Button from "components/atoms/Button"
import Icon from "components/atoms/Icon"
import { headerHeight, colors } from "utils/styled-constants"

const StyledHeader = styled.header`
  position: relative;
  transition: all 0.2s ease;
  transition-property: box-shadow, background-color;
  z-index: 100;
  box-shadow: ${props =>
    props.mobile
      ? "none"
      : props.invert
      ? "0px 10px 20px rgba(0, 45, 88, 0.2)"
      : "0px 14px 20px rgba(0, 45, 88, 0.05)"};
  height: ${headerHeight};
  background-color: ${props =>
    props.mobile ? colors.mediumBlue : props.bg ? colors[props.bg] : "white"};
`

const GlobalStyle = createGlobalStyle`
  html[data-scroll="0"] {
    .header {
      background-color: transparent;
      box-shadow: none;
    }
  }
`

// Default for the dynamic styles
StyledHeader.defaultProps = {
  boxShadow: "md",
}

const MenuButton = styled("button")`
  ${layout}
`

// https://stackoverflow.com/questions/42660907/using-reactcsstransitiongroup-with-styled-component
const MobileMenu = styled("div")`
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  transform: translateX(100%);
  transition-property: transform;
  ${css({
    bg: colors.mediumBlue,
  })}
  ${animation}
  &.menu-enter {
    transform: translateX(100%);
  }
  &.menu-enter-active {
    transform: translateX(0%);
  }
  &.menu-enter-done {
    transform: translateX(0%);
  }
  &.menu-exit {
    transform: translateX(0%);
  }
  &.menu-exit-active {
    transform: translateX(100%);
  }
  &.menu-exit-done {
    transform: translateX(100%);
  }
`

const StyledBgImg = styled("div")`
  background-image: url(${props => props.url});
  background-size: 232px 250px;
  background-repeat: no-repeat;
  background-position: right -10px bottom;
`

const HeaderDefault = ({
  invert,
  theme,
  bg,
  logo,
  logoInverse,
  menuIcon,
  menuIconInverse,
  closeIcon,
  links,
  button,
}) => {
  const [menu, setMenu] = useState(false)
  useStoreScroll()

  const Logo = ({ invert }) => (
    <Box width={{ _: 12, xs: 15 }}>
      <Link internalLink={{ slug: "/" }}>
        <Img
          fluid={invert ? logoInverse.fluid : logo.fluid}
          alt="Ten Coves Capital"
        />
      </Link>
    </Box>
  )

  //console.log("backgroundLeaf", backgroundLeaf)

  const activeMenuIcon = invert ? menuIconInverse : menuIcon

  return (
    <>
      <Box zIndex="100" position="fixed" width="100%">
        <ThemeSwitcher theme={theme}>
          <GlobalStyle />
          <StyledHeader className="header" bg={bg} invert={invert}>
            <StandardPadding py={0} height="100%">
              <Flex
                alignItems="center"
                justifyContent="space-between"
                height="100%"
              >
                <Logo invert={invert} />
                <Box
                  as="nav"
                  alignItems="center"
                  display={{ _: "none", lg: "flex" }}
                >
                  {links &&
                    links.map((link, i) => (
                      <NavLink
                        invert={invert}
                        ml={6}
                        variant="header-link"
                        partiallyActive={true}
                        key={i}
                        {...link}
                      />
                    ))}
                  {button && <Button {...button} ml={6} width={8} height={3} />}
                </Box>
                <MenuButton
                  onClick={() => setMenu(true)}
                  aria-label="Open menu"
                  width="25px"
                  height="16px"
                  display={{ _: "block", lg: "none" }}
                >
                  <Icon {...activeMenuIcon} width="25px" height="16px" />
                </MenuButton>
              </Flex>
            </StandardPadding>
          </StyledHeader>
          <CSSTransition
            in={menu}
            timeout="md"
            classNames="menu"
            mountOnEnter
            unmountOnExit
          >
            <MobileMenu
              transitionDuration="md"
              transitionTimingFunction="ease-in-out"
            >
              <Flex
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <div>
                  <StyledHeader
                    invert={invert}
                    mobile
                    as="div"
                    boxShadow="none"
                  >
                    <StandardPadding py={0} height="100%">
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        height="100%"
                      >
                        <Logo invert />
                        <MenuButton
                          onClick={() => setMenu(false)}
                          aria-label="Close menu"
                          width="21px"
                          height="20px"
                        >
                          <Icon {...closeIcon} width="21px" height="20px" />
                        </MenuButton>
                      </Flex>
                    </StandardPadding>
                  </StyledHeader>
                  <StandardPadding my={0} py={3}>
                    <Flex flexDirection="column" alignItems="flex-start">
                      {/* TODO: Rework font styles to allow the mobile links to be larger font*/}
                      {links &&
                        links.map((link, i) => (
                          <NavLink
                            mobile
                            mt={5}
                            variant="mobile-menu-link"
                            partiallyActive={true}
                            key={i}
                            {...link}
                          />
                        ))}
                      {button && (
                        <Button {...button} mt={7} width={8} height={3} />
                      )}
                    </Flex>
                  </StandardPadding>
                </div>
              </Flex>
            </MobileMenu>
          </CSSTransition>
        </ThemeSwitcher>
      </Box>
      <Box height="80px" />
    </>
  )
}

export default HeaderDefault

HeaderDefault.propTypes = {
  logo: PropTypes.shape({
    url: PropTypes.string,
    alternativeText: PropTypes.string,
  }),
  menuIcon: PropTypes.shape(Icon.strapiProps),
  closeIcon: PropTypes.shape(Icon.strapiProps),
  links: PropTypes.arrayOf(PropTypes.shape(Link.strapiProps)),
  button: PropTypes.shape(Button.strapiProps),
}

// removed theme and shadow and button and mobile background image from query

export const query = graphql`
  fragment DefaultHeader on ContentfulDefaultHeader {
    __typename
    logo {
      fluid(maxWidth: 240, quality: 100) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      description
    }
    logoInverse {
      fluid(maxWidth: 240, quality: 100) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      description
    }
    menuIcon {
      ...Icon
    }
    menuIconInverse {
      ...Icon
    }
    closeIcon {
      ...Icon
    }
    links {
      ...Link
    }
    linkedInLink
    investorLoginLink
  }
`
