import React from "react"
import PropTypes from "prop-types"
import Box from "components/atoms/Box"

const StandardPadding = ({ children, ...rest }) => {
  return (
    <Box px={{ _: 4, xs: 5, sm: 9, xl: 0 }} maxWidth={70} mx="auto" {...rest}>
      {children}
    </Box>
  )
}

export default StandardPadding

StandardPadding.defaultProps = {
  py: { _: 8, md: 11 },
}

StandardPadding.propTypes = {
  children: PropTypes.node.isRequired,
  ...Box.propTypes,
}
