import { props as stprops } from "@styled-system/should-forward-prop"
import { system } from "styled-system"

// Prevent forwarding unnecessary props to HTML components
// https://github.com/styled-system/styled-system/issues/1253

// Custom props that we've defined as variants
const customProps = ["type"]
stprops.concat(customProps)

const regex = new RegExp(`^(${stprops.join("|")})$`)

export const shouldForwardProp = (prop, defaultValidatorFn) => {
  return !regex.test(prop)
}

const marginProps = [
  "m",
  "margin",
  "mt",
  "marginTop",
  "mr",
  "marginRight",
  "mb",
  "marginBottom",
  "ml",
  "marginLeft",
  "mx",
  "marginX",
  "my",
  "marginY",
]

/*
 * Split up properties between margin-related properties and others.
 * Allows us to separate the margin props and apply them to a wrapper component.
 */
export const splitMarginProps = props => {
  const rest = JSON.parse(JSON.stringify(props))
  const margin = {}
  marginProps.forEach(marginProp => {
    if (props[marginProp]) {
      margin[marginProp] = props[marginProp]
      delete rest[marginProp]
    }
  })

  return { margin, rest }
}

export const animation = system({
  transitionDuration: {
    property: "transitionDuration",
    scale: "durations",
    defaultScale: ["0ms", "200ms", "500ms", "1000ms"],
    transform: (value, scale) => `${scale[value]}ms`,
  },
  animationDuration: {
    property: "animationDuration",
    scale: "durations",
    defaultScale: ["0ms", "200ms", "500ms", "1000ms"],
    transform: (value, scale) => `${scale[value]}ms`,
  },
  transitionTimingFunction: {
    property: "transitionTimingFunction",
    scale: "timingFunctions",
    defaultScale: {
      ease: "ease",
      "ease-in": "ease-in",
      "ease-out": "ease-out",
      "ease-in-out": "ease-in-out",
    },
  },
  animationTimingFunction: {
    property: "animationTimingFunction",
    scale: "timingFunctions",
    defaultScale: {
      ease: "ease",
      "ease-in": "ease-in",
      "ease-out": "ease-out",
      "ease-in-out": "ease-in-out",
    },
  },
})
