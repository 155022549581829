import React from "react"
import styled from "styled-components"

const UnderlinedSpan = styled("span")`
  text-decoration: underline;
`

const StyledLink = styled("a")`
  color: inherit;
  display: block;
  margin-bottom: 16px;
`

const EmailAndPhone = ({ email, phone, color }) => {
  return (
    <div style={{ color }}>
      <StyledLink href={`mailto:${email}`}>
        <UnderlinedSpan>press</UnderlinedSpan> - {email}
      </StyledLink>
      <StyledLink href={`tel:${phone}`}>
        <UnderlinedSpan>phone</UnderlinedSpan> - {phone}
      </StyledLink>
    </div>
  )
}

export default EmailAndPhone
