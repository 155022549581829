// https://github.com/rebassjs/grid
import styled from "styled-components"
import {
  space,
  color,
  layout,
  flexbox,
  border,
  shadow,
  position,
  compose,
} from "styled-system"
import propTypes from "@styled-system/prop-types"
import { shouldForwardProp } from "utils/styled-helpers.js"

const Box = styled("div").withConfig({ shouldForwardProp })(
  compose(space, color, layout, flexbox, border, shadow, position)
)

Box.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.flexbox,
  ...propTypes.border,
  ...propTypes.shadow,
}

export default Box
