import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import propTypes from "@styled-system/prop-types"
import styled from "styled-components"
import { color, space, layout, compose, variant } from "styled-system"
import css from "@styled-system/css"
import { splitMarginProps } from "utils/styled-helpers.js"
import { colors, defaultButtonVariants } from "utils/styled-constants.js"

import LinkArrowTeal from "images/link-arrow-teal.svg"
import LinkArrowSkyBlue from "images/link-arrow-sky-blue.svg"
import LinkArrowWhite from "images/link-arrow-white.svg"

import Flex from "components/atoms/Flex"
import { Text } from "components/atoms/Typography"
import Link from "components/utils/Link"
import Box from "components/atoms/Box"

import Arrow from "images/arrow.svg"

const ARROW_SPACING = 16
const ARROW_WIDTH = 18 // change if you change Arrow

const StyledButton = styled("button")`
  position: relative;
  ${"" /* width: fit-content; */}
  ${"" /* min-width: 214px; */}
  ${"" /* height: 40px; */}
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent || "space-between"};
  padding-left: 21px;
  padding-right: 18px;
  overflow: hidden;
  font-weight: 500;
  &:hover {
    .arrows {
      transform: translateX(0);
    }
    .animatedDiv {
      opacity: 0.15;
      width: 100%;
    }
  }
  ${compose(space, color, layout)}
  ${variant({
    scale: "buttons",
    variants: defaultButtonVariants,
  })}
`

const linkArrowTransition = `
  transition: opacity 0.2s ease;
  .arrow {
    transition: transform 0.2s ease;
  }
  &:hover {
    opacity: 0.7;
    .arrow {
      transform: translateX(5px);
    }
  }
`

const StyledLink = styled(Link)`
  ${linkArrowTransition}
`

const LinkButton = styled.button`
  ${linkArrowTransition}
`

const ArrowWrapper = styled.div`
  overflow: hidden;
  height: auto;
  width: 18px;
`

const ArrowInner = styled.div`
  width: ${ARROW_WIDTH * 2 + ARROW_SPACING}px;
  transition: transform 0.2s ease;
  transform: translateX(${-ARROW_WIDTH - ARROW_SPACING}px);
`

const AnimatedDiv = styled.div`
  display: block;
  border-radius: 50px;
  width: 0;
  transform: translateX(-50%);
  height: 100%;
  transition: width 0.2s ease, opacity 0.2s ease;
  opacity: 0;
`

const AnimatedDivWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  width: 100%;
  height: 100%;
`

const TextWrapper = styled(Flex)`
  position: relative;
  z-index: 3;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`

/*
 * Button component
 * Variants "default" and "primary" are defined in styled-system theme
 * There is also a "link" variant that turns the button into a regular text link
 *
 */
const Button = ({ text, color, noArrow, variant, link, onClick, ...rest }) => {
  let white, teal, skyBlue
  white = teal = skyBlue = false
  switch (color) {
    case "white":
      white = true
      break
    case "teal":
      teal = true
      break
    case "skyBlue":
      skyBlue = true
      break
    default:
      skyBlue = true
  }
  const linkVariant = variant === "link"
  // If the button is actually a link
  if (link) {
    // Split up margin props to apply to box outside of the link
    const { margin, rest: buttonProps } = splitMarginProps(rest)
    if (linkVariant) {
      buttonProps.width = "auto"
      buttonProps.height = "auto"
    }
    return (
      <Box {...margin}>
        <StyledLink {...link}>
          {linkVariant && (
            <Text color={color} fontWeight="bold" fontSize={16}>
              {text}
              <Box display="inline" ml={4}>
                {teal && !noArrow && (
                  <LinkArrowTeal
                    className="arrow"
                    style={{ verticalAlign: "middle" }}
                  />
                )}
                {skyBlue && !noArrow && (
                  <LinkArrowSkyBlue
                    className="arrow"
                    style={{ verticalAlign: "middle" }}
                  />
                )}
                {white && !noArrow && (
                  <LinkArrowWhite
                    className="arrow"
                    style={{ verticalAlign: "middle" }}
                  />
                )}
              </Box>
            </Text>
          )}
          {!linkVariant && (
            <StyledButton as="div" variant={variant} {...buttonProps}>
              <AnimatedDivWrapper>
                <AnimatedDiv className="animatedDiv" />
              </AnimatedDivWrapper>
              <TextWrapper justifyContent="space-between">
                <Text color="inherit" fontWeight="inherit" pr={4} fontSize={16}>
                  {text}
                </Text>
                <ArrowWrapper>
                  <ArrowInner className="arrows">
                    <Arrow style={{ marginRight: `${ARROW_SPACING}px` }} />
                    <Arrow />
                  </ArrowInner>
                </ArrowWrapper>
              </TextWrapper>
            </StyledButton>
          )}
        </StyledLink>
      </Box>
    )
  }

  // Else it's a real button with an onClick function
  if (linkVariant) {
    return (
      <LinkButton onClick={onClick}>
        <Text color={color} fontWeight="bold" fontSize={16}>
          {text}
          <Box display="inline" ml={4}>
            {teal && !noArrow && (
              <LinkArrowTeal
                className="arrow"
                style={{ verticalAlign: "middle" }}
              />
            )}
            {skyBlue && !noArrow && (
              <LinkArrowSkyBlue
                className="arrow"
                style={{ verticalAlign: "middle" }}
              />
            )}
            {white && !noArrow && (
              <LinkArrowWhite
                className="arrow"
                style={{ verticalAlign: "middle" }}
              />
            )}
          </Box>
        </Text>
      </LinkButton>
    )
  }
  return (
    <StyledButton onClick={onClick} variant={variant} {...rest}>
      <AnimatedDivWrapper>
        <AnimatedDiv className="animatedDiv" />
      </AnimatedDivWrapper>
      <TextWrapper justifyContent="center">
        <Text color="inherit" fontWeight="inherit" fontSize={16}>
          {text}
        </Text>
      </TextWrapper>
    </StyledButton>
  )
}

export default Button

Button.strapiProps = {
  variant: PropTypes.oneOf(["default", "primary", "link"]).isRequired,
  text: PropTypes.string.isRequired,
}

Button.propTypes = {
  ...Button.strapiProps,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}

Button.defaultProps = {
  variant: "default",
}

export const query = graphql`
  fragment Button on ContentfulButton {
    text
    variant
    link {
      ...Link
    }
  }
`
