import React from "react"
import PropTypes from "prop-types"
import propTypes from "@styled-system/prop-types"
import styled from "styled-components"
import {
  typography,
  space,
  color,
  layout,
  variant,
  compose,
  system,
} from "styled-system"
import css from "@styled-system/css"
import { shouldForwardProp } from "utils/styled-helpers.js"

const Typography = styled("span").withConfig({ shouldForwardProp })(
  { margin: 0 },
  css({
    // These can be overridden by the variant and props
    fontWeight: "normal",
    color: "mediumBlue",
    // TODO should this always be here? maybe there are certains designs / sections
    // where every link shouldn't be the primary color (header/footer)
  }),
  variant({ scale: "typography" }),
  compose(
    system({
      // letterSpacing: true,
      // textTransform: true,
      fontWeight: {
        property: "fontWeight",
        scale: "fontWeights",
      },
      fontSize: {
        property: "fontSize",
        scale: "fontSizes",
      },
    }),
    typography,
    space,
    color,
    layout
  )
)

/**
 * Title
 */
export const Title = ({ children, variant, as, ...rest }) => {
  return (
    <Typography
      variant={{ _: `${variant}-mobile`, sm: variant }}
      as={as ? as : variant}
      fontFamily="heading"
      {...rest}
    >
      {children}
    </Typography>
  )
}

Title.propTypes = {
  variant: PropTypes.oneOf(["h1", "h2", "h3"]).isRequired,
  as: PropTypes.oneOf(["h1", "h2", "h3"]),
  children: PropTypes.node.isRequired,
  ...propTypes.typography,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}

Title.defaultProps = {
  variant: "h2",
}

/**
 * Paragraph
 */
export const Paragraph = ({ children, markdown, ...rest }) => {
  return (
    <Typography
      as={markdown ? "div" : "p"}
      variant="p"
      fontFamily="body"
      {...rest}
    >
      {children}
    </Typography>
  )
}

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  ...propTypes.typography,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}

/**
 * Label
 */
export const Label = ({ children, ...rest }) => {
  return (
    <Typography as="div" variant="label" fontFamily="body" {...rest}>
      {children}
    </Typography>
  )
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
  ...propTypes.typography,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}

/**
 * Text
 */
export const Text = ({ children, as, variant, ...rest }) => {
  return (
    <Typography
      as={as ? as : "span"}
      variant={variant ? variant : "text"}
      fontFamily="body"
      {...rest}
    >
      {children}
    </Typography>
  )
}

Text.propTypes = {
  children: PropTypes.node.isRequired,
  ...propTypes.typography,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}

/**
 * LinkText
 */
export const LinkText = ({ children, variant, ...rest }) => {
  return (
    <Typography
      as="span"
      variant={variant ? variant : "link"}
      fontFamily="body"
      {...rest}
    >
      {children}
    </Typography>
  )
}

LinkText.propTypes = {
  children: PropTypes.node.isRequired,
  ...propTypes.typography,
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
}
