//import { createShouldForwardProp } from "@styled-system/should-forward-prop"

import { flex } from "styled-system"

// Breakpoint definitions using the same breakpoints as TailwindCSS
// https://tailwindcss.com/docs/breakpoints/
// https://styled-system.com/responsive-styles
const breakpointsArray = ["425px", "640px", "768px", "1024px", "1280px"]
export const breakpoints = []
breakpoints.xs = breakpointsArray[0]
breakpoints.sm = breakpointsArray[1]
breakpoints.md = breakpointsArray[2]
breakpoints.lg = breakpointsArray[3]
breakpoints.xl = breakpointsArray[4]

export const headerHeight = "80px"

// Margin and padding values
// https://styled-system.com/api#space
export const space = [
  0, // 0
  2, // 1
  4, // 2
  8, // 3
  16, // 4
  24, // 5
  32, // 6
  48, // 7
  64, // 8
  80, // 9
  96, // 10
  112, // 11
  128, // 12
  256, // 13
  512, // 14
]

// Height and width
// https://styled-system.com/api#layout
export const sizes = [
  0,
  16,
  32,
  48,
  64,
  80,
  96,
  112,
  128,
  144,
  160,
  176,
  192,
  208,
  224,
  240,
  256,
  272,
  288,
  304,
  320,
  336,
  352,
  368,
  384,
  400,
  416,
  432,
  448,
  464,
  480,
  496,
  512,
  528,
  544,
  560,
  576,
  592,
  608,
  624,
  640,
  656,
  672,
  688,
  704,
  720,
  736,
  752,
  768,
  784,
  800,
  816,
  832,
  848,
  864,
  880,
  896,
  912,
  928,
  944,
  960,
  976,
  992,
  1008,
  1024,
  1040,
  1056,
  1072,
  1088,
  1104,
  1120,
  1136,
  1152,
  1168,
  1184,
]

export const defaultButtonVariants = {
  submit: {
    fontWeight: 600,
    color: "white",
    "background-image": `url("/submit-background.png")`,
    transition: "background-position-x 0.3s",
    "transition-timing-function": "cubic-bezier(.13,.2,.04,1)",
    "&:hover": {
      "background-position-x": "100%",
    },
  },
  default: {
    color: "darkBlue",
    bg: "gray",
    ".animatedDiv": {
      bg: "mediumBlue",
    },
  },
  white: {
    color: "darkBlue",
    bg: "white",
    ".animatedDiv": {
      bg: "mediumBlue",
    },
  },
  beige: {
    color: "darkBlue",
    bg: "beige",
    ".animatedDiv": {
      bg: "#8F550C",
    },
  },
  lightBlue: {
    color: "white",
    bg: "lightBlue",
    ".animatedDiv": {
      bg: "darkBlue",
    },
  },
  gray: {
    color: "darkBlue",
    bg: "gray",
    ".animatedDiv": {
      bg: "mediumBlue",
    },
  },
  gold: {
    color: "darkBlue",
    bg: "gold",
    ".animatedDiv": {
      bg: "mediumBlue",
    },
  },
}

const fontSizes = [10, 13, 14, 18, 24, 36, 48]

const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
}

// Default colors to use in the theme.
export const colors = {
  white: "#fff",
  gray: "#EBF0F4",
  beige: "#EDE5DB",
  gold: "#F0D4A2",
  darkGold: "#DCBA7E",
  subtitleBlue: "#ACC7D5",
  mediumBlue: "#2C4767",
  darkBlue: "#1B2F4C",
  grayBlue: "#558095",
  skyBlue: "#719DF4",
  footerBlue: "#93BBF9",
  lightBlue: "#5E7F93",
  lighterBlue: "#B1C6D3",
  teal: "#44E3DF",
  shadow: "rgba(0,12,18,0.15)",
}

//Somewhat based on the theme specification of Theme UI
//https://theme-ui.com/theme-spec
export const theme = {
  colors,
  fonts: {
    heading: "roboto, sans-serif",
    body: "roboto, sans-serif",
  },
  fontSizes,
  fontWeights,
  lineHeights: {
    heading: "1.3em",
    body: "1.4em",
  },
  typography: {
    h1: {
      fontSize: 42,
      fontWeight: fontWeights.regular,
    },
    "h1-mobile": {
      fontSize: 32,
      fontWeight: fontWeights.regular,
    },
    h2: {
      fontSize: 32,
      lineHeight: "42px",
      fontWeight: fontWeights.regular,
    },
    "h2-mobile": {
      fontSize: 32,
      fontWeight: fontWeights.regular,
    },
    "h2-carousel": {
      fontSize: fontSizes[5],
      lineHeight: "46px",
      fontWeight: 600,
    },
    "h2-carousel-mobile": {
      fontSize: fontSizes[4],
      lineHeight: "36px",
      fontWeight: 600,
    },
    h3: {
      fontSize: "28px",
      fontWeight: fontWeights.medium,
    },
    "h3-mobile": {
      fontSize: fontSizes[4],
      fontWeight: fontWeights.medium,
    },
    p: {
      fontSize: 15,
      lineHeight: "24px",
      fontWeight: fontWeights.regular,
    },
    "link-blue": {
      fontSize: fontSizes[3],
      lineHeight: "24px",
      fontWeight: fontWeights.regular,
      color: colors.subtitleBlue,
    },
    "link-gold": {
      fontSize: fontSizes[3],
      lineHeight: "24px",
      fontWeight: fontWeights.regular,
      color: colors.gold,
    },
    label: {
      fontSize: "11px",
      letterSpacing: "1.2px",
      textTransform: "uppercase",
      fontWeight: fontWeights.medium,
    },
    "header-link": {
      fontSize: "16px",
      letterSpacing: "0.7px",
      color: "inherit",
      fontWeight: 500,
    },
    "footer-link": {
      fontSize: "16px",
      letterSpacing: "0.7px",
      color: colors.footerBlue,
      fontWeight: 400,
      transition: "opacity 0.1s ease",
      "&:hover": {
        opacity: 0.5,
      },
    },
    "mobile-menu-link": {
      fontSize: fontSizes[4],
      color: colors.white,
      fontWeight: fontWeights.semiBold,
    },
    "small-label": {
      fontSize: fontSizes[0],
      textTransform: "uppercase",
      fontWeight: fontWeights.bold,
    },
    "grid-item-title": {
      fontSize: fontSizes[3],
      fontWeight: 500,
      letterSpacing: "0.6px",
    },
    "grid-item-subtitle": {
      fontSize: fontSizes[2],
      fontWeight: fontWeights.regular,
      letterSpacing: "0.7px",
    },
    "grid-item-supertitle": {
      fontSize: fontSizes[1],
      fontWeight: fontWeights.regular,
      letterSpacing: "0.7px",
      color: colors.lightBlue,
    },
    text: {
      fontSize: fontSizes[2],
      fontWeight: fontWeights.medium,
    },
    "footer-text": {
      fontSize: "12px",
      fontWeight: fontWeights.regular,
      color: colors.footerBlue,
    },
  },
  radii: {
    sm: 6,
    md: 12,
    lg: 24,
  },
  shadows: {
    sm: `0 5px 10px ${colors.shadow}`,
    md: `0 5px 25px ${colors.shadow}`,
    lg: `0 5px 35px ${colors.shadow}`,
  },
  buttons: defaultButtonVariants,
  space,
  sizes,
  breakpoints,
  durations: {
    fast: 200,
    md: 500,
    slow: 1000,
  },
  timingFunctions: {
    ease: "ease",
    "ease-in": "ease-in",
    "ease-out": "ease-out",
    "ease-in-out": "ease-in-out",
  },
  reveal: {
    effect: "fade-up",
    duration: "slow",
    timingFunction: "ease",
  },
  themes: {
    dark: {
      colors: {
        primary: colors.primary,
        text: colors.white,
        textOnPrimary: colors.white,
        background: [colors.dark, colors.dark],
      },
    },
    light: {
      colors: {
        primary: colors.primary,
        text: colors.black,
        textOnPrimary: colors.white,
        background: [colors.white, colors.gray],
      },
    },
    highlight: {
      colors: {
        primary: colors.white,
        text: colors.white,
        textOnPrimary: colors.primary,
        background: [colors.primary, colors.primary],
      },
    },
  },
}
