import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import css from "@styled-system/css"
import { color } from "styled-system"

import ThemeSwitcher from "components/utils/ThemeSwitcher"

import Box from "components/atoms/Box"
import Flex from "components/atoms/Flex"
import { Text } from "components/atoms/Typography"
import StandardPadding from "components/atoms/StandardPadding"
import NavLink from "components/atoms/NavLink"

import { colors } from "utils/styled-constants"
import Link from "components/utils/Link"

const StyledFooter = styled("footer")(
  css({
    minHeight: 6,
  }),
  color
)

const StyledSocialLinks = styled("div")`
  a {
    margin-right: 18px;
    &:hover {
      svg {
        opacity: 0.5;
      }
    }
  }
  margin-right: 20px;
  svg {
    transition: opacity 0.1s ease;
  }
`

const FooterTextLink = styled(Text)`
  color: inherit;
  ${css({
    ["&:hover"]: {
      color: "teal",
    },
  })}
`

const FooterMinimal = ({
  theme,
  links,
  copyrightLinks,
  linkedInLink,
  investorLoginLink,
}) => {
  return (
    <ThemeSwitcher theme={theme.theme}>
      <StyledFooter bg="darkBlue">
        <StandardPadding py={0}>
          <Flex
            py={{ _: 8, lg: 8 }}
            minHeight={6}
            flexDirection={{ _: "column", lg: "row" }}
            alignItems={{ _: "flex-start", lg: "flex-start" }}
            justifyContent="space-between"
          >
            <Flex
              flexDirection={{ _: "column-reverse", sm: "row" }}
              flexWrap="nowrap"
            >
              <Flex mb={{ _: 6, lg: 0 }} flexDirection={{ _: "column" }}>
                {links &&
                  links.map((link, i) => (
                    <NavLink
                      footer
                      mb={{ _: 2, sm: 0 }}
                      ml={{ _: 0, lg: 6 }}
                      variant="footer-link"
                      partiallyActive={true}
                      key={i}
                      {...link}
                    />
                  ))}
                <NavLink
                  footer
                  py="8px"
                  mb={{ _: 2, sm: 0 }}
                  ml={{ _: 0, lg: 6 }}
                  variant="footer-link"
                  linkText="Investor Login"
                  externalLink={investorLoginLink}
                />
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              alignItems={{ _: "flex-start", lg: "flex-end" }}
              mt={{ _: 6, sm: 0 }}
            >
              {linkedInLink && (
                <a href={linkedInLink}>
                  <Text fontSize="16px" variant="footer-link">
                    LinkedIn
                  </Text>
                </a>
              )}
              {copyrightLinks && (
                <Flex
                  flexDirection="row"
                  alignItems={{ _: "center" }}
                  mt={{ _: 2, sm: 0 }}
                  ml={{ _: 0, lg: 6 }}
                >
                  {copyrightLinks.map((link, i) => (
                    <NavLink
                      footer
                      variant="footer-link"
                      partiallyActive={true}
                      // mx={{ _: 3 }}
                      key={i}
                      {...link}
                    />
                  ))}
                </Flex>
              )}

              <Text mt={5} variant="footer-text">
                ©{new Date().getFullYear()} Ten Coves Capital. All rights
                reserved.
              </Text>
              <Text mt={4} variant="footer-text">
                <FooterTextLink
                  as="a"
                  href="https://www.getmorphic.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Created with Morphic
                </FooterTextLink>{" "}
                /{" "}
                <FooterTextLink
                  as="a"
                  href="https://www.westonbaker.com/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Designed by WBCG
                </FooterTextLink>
              </Text>
            </Flex>
          </Flex>
        </StandardPadding>
      </StyledFooter>
    </ThemeSwitcher>
  )
}

export default FooterMinimal

FooterMinimal.defaultProps = {
  theme: { theme: "default", bg: 0 },
}

FooterMinimal.propTypes = {
  theme: PropTypes.shape({
    theme: PropTypes.string,
    bg: PropTypes.number,
  }),
  links: PropTypes.arrayOf(PropTypes.shape(Link.strapiProps)),
  copyrightText: PropTypes.string,
}

export const query = graphql`
  fragment Footer on ContentfulFooter {
    __typename

    copyrightLinks {
      ...Link
    }
  }
`
