import React, { useContext } from "react"
import { ThemeContext, ThemeProvider } from "styled-components"
import PropTypes from "prop-types"
import { breakpoints } from "utils/styled-constants"

const ThemeSwitcher = ({ theme, children }) => {
  let currentTheme = useContext(ThemeContext)

  if (theme !== "default") {
    const newTheme = JSON.parse(JSON.stringify(currentTheme)) // make a copy
    // replace keys old theme with new theme
    Object.keys(currentTheme.themes[theme]).forEach(
      property => (newTheme[property] = currentTheme.themes[theme][property])
    )

    // Need to provide original breakpoints to use "sm, md, lg, xl" notation
    // These are removed when doing JSON parse and stringify
    newTheme.breakpoints = breakpoints

    return <ThemeProvider theme={newTheme}>{children}</ThemeProvider>
  }
  return <>{children}</>
}

export default ThemeSwitcher

ThemeSwitcher.defaultProps = {
  theme: "default",
}

ThemeSwitcher.propTypes = {
  theme: PropTypes.oneOf(["default", "light", "dark", "highlight"]).isRequired,
  children: PropTypes.node.isRequired,
}
