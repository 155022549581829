import { useEffect } from "react"

export default () => {
  const isClient = typeof window === "object"

  // The debounce function receives our function as a parameter
  const debounce = fn => {
    // This holds the requestAnimationFrame reference, so we can cancel it if we wish
    let frame

    // The debounce function returns a new function that can receive a variable number of arguments
    return (...params) => {
      // If the frame variable has been defined, clear it now, and queue for next frame
      if (frame) {
        cancelAnimationFrame(frame)
      }

      // Queue our function call for the next frame
      frame = requestAnimationFrame(() => {
        // Call our function and pass any params we received
        fn(...params)
      })
    }
  }

  useEffect(() => {
    if (!isClient) {
      return
    }

    const storeScroll = () => {
      document.documentElement.dataset.scroll = window.pageYOffset
    }

    const debouncedStoreScroll = debounce(storeScroll)

    document.addEventListener("scroll", debouncedStoreScroll, { passive: true })
    storeScroll()
    return () => {
      document.removeEventListener("scroll", debouncedStoreScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClient])
}
