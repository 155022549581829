import React from "react"
import PropTypes from "prop-types"
import propTypes from "@styled-system/prop-types"
import { Link as GatsbyLink, StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import css from "@styled-system/css"
import { typography, space, compose } from "styled-system"

import { colors } from "utils/styled-constants"

// Apply margin and padding to links
const StyledGatsbyLink = styled(GatsbyLink)(
  css({
    ".card": {
      transition: "box-shadow 0.4s ease",
    },
    ":hover .card": {
      "box-shadow": "0px 6px 35px #c5c9d9",
    },
  }),
  compose(space, typography)
)

const StyledAnchor = styled("a")(
  css({
    color: `${props => (props.invert ? colors.darkBlue : "white")}`,

    color: "white",
    textDecoration: "none",
    ".card": {
      transition: "box-shadow 0.4s ease",
    },
    ":hover .card": {
      "box-shadow": "0px 6px 35px #c5c9d9",
    },
  }),
  compose(space, typography)
)

/*
 * Switches between a Gatsby link and a regular anchor tag based on props
 * The "to" prop is the pageId of an internal page to link to
 */
const Link = ({
  internalLink,
  externalLink,
  indexPageSlug,
  children,
  ...rest
}) => {
  if (internalLink) {
    // Contentful alteration
    let pagePath
    if (internalLink.slug === indexPageSlug || internalLink.slug === "/") {
      pagePath = "/"
    } else {
      pagePath = "/" + internalLink.slug + "/"
    }

    if (pagePath) {
      return (
        <StyledGatsbyLink to={pagePath} {...rest}>
          {children}
        </StyledGatsbyLink>
      )
    }
  }

  if (externalLink) {
    // Always open external links in a new tab
    return (
      <StyledAnchor
        href={externalLink}
        target="_blank"
        rel="noopener noreferrer"
        {...rest}
      >
        {children}
      </StyledAnchor>
    )
  }

  return <>{children}</>
}

export default props => (
  <StaticQuery
    query={graphql`
      query LinkIndexPageQuery {
        contentfulSite {
          indexPage {
            slug
          }
        }
      }
    `}
    render={data => (
      <Link indexPageSlug={data.contentfulSite.indexPage.slug} {...props} />
    )}
  />
)
//
// Link.strapiProps = {
//   to: PropTypes.object, // Number corresponding to the page id or exact string path
//   href: PropTypes.string, // Url of external site
//   hrefText: PropTypes.string,
// }
//
// Link.propTypes = {
//   ...Link.strapiProps,
//   indexPageSlug: PropTypes.string.isRequired,
//   children: PropTypes.node.isRequired,
//   ...propTypes.space,
// }

export const query = graphql`
  fragment Link on ContentfulLink {
    internalLink {
      title
      slug
    }
    externalLink
    linkText
  }
`
